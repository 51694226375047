import React from "react"

import { BodyText, List, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

import orphans from "../orphans.json";

const Page = () => (
  <Layout>
    <SEO title="The Unclaimed" />
    <PageContainer>
      <PageContainer.Title>Looking for a good home</PageContainer.Title>
      <BodyText>
        All of these delicious flavours I've made over time, but they've not found a suitor. Could that be you?
      </BodyText>
      <List>
        {orphans.map(o => (
          <List.Item key={o}><List.Text>{o}</List.Text></List.Item>
        ))}
      </List>
      <ContactLink>I'm ready to adopt</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
